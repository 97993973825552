/* entire app */
html,
body {
  background: #eef6f6 !important;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/* end of entire app */

/* navbar section */
.navbar {
  background: white;
  border-bottom-right-radius: 15%;
  border-bottom-left-radius: 15%;
  text-align: center;
}

.navbar-brand {
  margin-left: 1em;
}

.navbar-toggler {
  margin-right: 1em;
}

.navbar .nav-link,
.navbar-text {
  color: black !important;
  border-radius: 10%;
}

.navbar .nav-link:hover {
  background: #eef6f6;
}

.navbar .nav-link.active {
  background: #eef6f6;
}

.navbar .nav-link.logout:hover {
  color: red !important;
  background: none;
}
/* end of navbar section */

/* building card tabs */
.tabs .nav-link,
.navbar-text {
  color: black !important;
}

.tabs .nav-link:hover {
  background: #eef6f6;
}

.tabs .nav-link.active {
  font-weight: 600;
}

/* end of building card tabs */

/* entire pages below navbar, above footer*/
.all-pages {
  margin-top: 2em;
  margin-bottom: 0em;
  flex: 1;
}

.diy-jumbotron {
  max-width: 90%;
}
/* end of entire pages, above footer */

/* pills pages - navigation */
.nav.nav-pills {
  margin-bottom: 1em;
}

.nav.nav-pills .nav-link {
  color: #06253e;
}

.nav.nav-pills .nav-link:hover {
  background-color: rgba(6, 37, 62, 0.1);
}

.nav.nav-pills .nav-link.active {
  background-color: rgba(6, 37, 62, 1);
  color: white;
  font-weight: 700;
}

/* end of pills pages - navigation */

/* pills pages - sort misc fields */
.sort-bar {
  padding-bottom: 2em;
}
/* end of pills pages - sort misc fields */

/* pills pages - building cards and info windows */
.first-phone-num {
  margin-top: 0.5em;
  margin-bottom: 0em;
}

.address-phone-link {
  text-decoration: none;
}

.address-phone-link:hover {
  text-decoration: underline;
}

.table {
  margin-top: 0.5em;
  margin-bottom: 0em;
}
/* pills pages - end of building cards and info windows */

/* footer */
.footer-img {
  width: 100%;
}

.footer {
  padding-top: 1em;
}

.footer-nav-link-custom {
  color: black;
  text-decoration: none;
}

.footer-nav-link-custom:hover,
.footer-nav-link-custom:active {
  text-decoration: underline;
}
/* end of footer */

/* Listing accordion */
.accordion-button:not(.collapsed) {
  background-color: #f0ecec !important;
  color: black !important;
  font-weight: 700;
}

.accordion-button:hover {
  background-color: #f0ecec !important;
}

.accordion-button:focus {
  box-shadow: none;
}
/* End of listing accordion */

/* misc */
.break-line-light {
  color: lightblue;
}

.delete-link:hover {
  color: red !important;
}

.diy-font-italic {
  font-style: italic;
}

.diy-outline-info-button {
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  background: none !important;
}

.diy-outline-info-button:hover,
.diy-solid-info-button {
  color: white !important;
  background: #17a2b8 !important;
  border-color: #51b7c8 !important;
}

.diy-outline-info-button:active,
.diy-solid-info-button:hover,
.diy-solid-info-button:active {
  color: white !important;
  background: #148596 !important;
  border-color: #127a8b !important;
}

.scrollable-card-policy {
  overflow-y: auto;
  max-height: 400px;
}

.units-avail-badge {
  position: relative;
  left: 0.5em;
}
/* end of misc */

/* map and building lists */
.map-and-list-container {
  width: 100%;
  height: 78vh;
}

@media (max-width: 768px) {
  .map-and-list-container {
    height: 70vh;
  }
}

@media (max-width: 576px) {
  .map-and-list-container {
    height: 65vh;
  }
}
/* end of map and building lists */
